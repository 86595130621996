<template>
    <v-dialog v-model="appCharts.showGabriel" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid>
            <v-card>
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.showGabriel = false"></v-btn>
                </v-toolbar>

                <v-row class="mt-10">
                    <h1>teste</h1>
                </v-row>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>

import { useAppCharts } from "../../../stores/appCharts";

export default {
    name: "gabriel",
    data() {
        return {
            count: 0
        }
    },
    computed: {
        appCharts() {
            return useAppCharts();
        }
    }
}
</script>